exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-aftersales-jsx": () => import("./../../../src/pages/aftersales.jsx" /* webpackChunkName: "component---src-pages-aftersales-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-config-anelar-jsx": () => import("./../../../src/pages/config-anelar.jsx" /* webpackChunkName: "component---src-pages-config-anelar-jsx" */),
  "component---src-pages-config-compactram-jsx": () => import("./../../../src/pages/config-compactram.jsx" /* webpackChunkName: "component---src-pages-config-compactram-jsx" */),
  "component---src-pages-config-electram-jsx": () => import("./../../../src/pages/config-electram.jsx" /* webpackChunkName: "component---src-pages-config-electram-jsx" */),
  "component---src-pages-config-microram-jsx": () => import("./../../../src/pages/config-microram.jsx" /* webpackChunkName: "component---src-pages-config-microram-jsx" */),
  "component---src-pages-config-modulram-jsx": () => import("./../../../src/pages/config-modulram.jsx" /* webpackChunkName: "component---src-pages-config-modulram-jsx" */),
  "component---src-pages-config-rotoram-jsx": () => import("./../../../src/pages/config-rotoram.jsx" /* webpackChunkName: "component---src-pages-config-rotoram-jsx" */),
  "component---src-pages-config-turboram-jsx": () => import("./../../../src/pages/config-turboram.jsx" /* webpackChunkName: "component---src-pages-config-turboram-jsx" */),
  "component---src-pages-configuradores-jsx": () => import("./../../../src/pages/configuradores.jsx" /* webpackChunkName: "component---src-pages-configuradores-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-anelar-jsx": () => import("./../../../src/pages/products/anelar.jsx" /* webpackChunkName: "component---src-pages-products-anelar-jsx" */),
  "component---src-pages-products-cold-chamber-jsx": () => import("./../../../src/pages/products/cold-chamber.jsx" /* webpackChunkName: "component---src-pages-products-cold-chamber-jsx" */),
  "component---src-pages-products-compactram-jsx": () => import("./../../../src/pages/products/compactram.jsx" /* webpackChunkName: "component---src-pages-products-compactram-jsx" */),
  "component---src-pages-products-electram-jsx": () => import("./../../../src/pages/products/electram.jsx" /* webpackChunkName: "component---src-pages-products-electram-jsx" */),
  "component---src-pages-products-fermentation-chamber-jsx": () => import("./../../../src/pages/products/fermentation-chamber.jsx" /* webpackChunkName: "component---src-pages-products-fermentation-chamber-jsx" */),
  "component---src-pages-products-kebab-jsx": () => import("./../../../src/pages/products/kebab.jsx" /* webpackChunkName: "component---src-pages-products-kebab-jsx" */),
  "component---src-pages-products-microram-jsx": () => import("./../../../src/pages/products/microram.jsx" /* webpackChunkName: "component---src-pages-products-microram-jsx" */),
  "component---src-pages-products-modulram-jsx": () => import("./../../../src/pages/products/modulram.jsx" /* webpackChunkName: "component---src-pages-products-modulram-jsx" */),
  "component---src-pages-products-pastel-de-nata-jsx": () => import("./../../../src/pages/products/pastel-de-nata.jsx" /* webpackChunkName: "component---src-pages-products-pastel-de-nata-jsx" */),
  "component---src-pages-products-proofers-jsx": () => import("./../../../src/pages/products/proofers.jsx" /* webpackChunkName: "component---src-pages-products-proofers-jsx" */),
  "component---src-pages-products-rotoram-jsx": () => import("./../../../src/pages/products/rotoram.jsx" /* webpackChunkName: "component---src-pages-products-rotoram-jsx" */),
  "component---src-pages-products-turboram-jsx": () => import("./../../../src/pages/products/turboram.jsx" /* webpackChunkName: "component---src-pages-products-turboram-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-templates-blog-post-en-js": () => import("./../../../src/templates/blog-post-en.js" /* webpackChunkName: "component---src-templates-blog-post-en-js" */),
  "component---src-templates-blog-post-es-js": () => import("./../../../src/templates/blog-post-es.js" /* webpackChunkName: "component---src-templates-blog-post-es-js" */),
  "component---src-templates-blog-post-fr-js": () => import("./../../../src/templates/blog-post-fr.js" /* webpackChunkName: "component---src-templates-blog-post-fr-js" */),
  "component---src-templates-blog-post-pt-br-js": () => import("./../../../src/templates/blog-post-pt-br.js" /* webpackChunkName: "component---src-templates-blog-post-pt-br-js" */),
  "component---src-templates-blog-post-pt-js": () => import("./../../../src/templates/blog-post-pt.js" /* webpackChunkName: "component---src-templates-blog-post-pt-js" */)
}

